import { useEffect } from "react";
import * as THREE from "three";
import { PointLight } from "three";
import { LineSegments } from "three";
import { Mesh } from "three";
import { Vector3 } from "three";
import { Group } from "three";
import { Color } from "three";
import { DoubleSide, LineBasicMaterial } from "three";
import { MeshPhongMaterial } from "three";
import { TorusGeometry } from "three";
import { OrbitControls } from "./orbitControls.js";

let camera, scene, renderer;
let group1, group2;
const zAxis = new Vector3(1, 0, 0);

function init() {
  const anchor = document.getElementById("cool-three-d-anchor");
  anchor.innerHTML = "";

  // camera = new THREE.PerspectiveCamera( 70, anchor.clientWidth / anchor.clientHeight, 0.01, 50 );
  const scale = 0.08;
  camera = new THREE.OrthographicCamera(
    (anchor.clientWidth / -2) * scale,
    (anchor.clientWidth / 2) * scale,
    (anchor.clientHeight / 2) * scale,
    (anchor.clientHeight / -2) * scale,
    1,
    1000
  );
  camera.position.z = 30;

  scene = new THREE.Scene();
  scene.background = new Color(0x444444);

  const lights = [];
  lights[0] = new PointLight(0xffffff, 1, 0);
  lights[1] = new PointLight(0xffffff, 1, 0);
  lights[2] = new PointLight(0xffffff, 1, 0);

  lights[0].position.set(0, 200, 0);
  lights[1].position.set(100, 200, 100);
  lights[2].position.set(-100, -200, -100);

  scene.add(lights[0]);
  scene.add(lights[1]);
  scene.add(lights[2]);

  const lineMaterial = new LineBasicMaterial({
    color: 0xffffff,
    transparent: true,
    opacity: 0.5,
  });
  const meshMaterial = new MeshPhongMaterial({
    color: 0xffdd45,
    emissive: 0x8c7300,
    side: DoubleSide,
    flatShading: true,
  });

  // BRIGHT gold
  // const meshMaterial = new MeshPhongMaterial( { color: 0x156289, emissive: 0xffdd45, side: DoubleSide, flatShading: true } );

  // Reference
  // const meshMaterial = new MeshPhongMaterial( { color: 0x156289, emissive: 0x072534, side: DoubleSide, flatShading: true } );

  group1 = new Group();
  group2 = new Group();

  const x_separation = 10;
  const geometry = new TorusGeometry(10, 2, 16, 100);
  const geometry2 = new TorusGeometry(10, 2, 16, 100);

  // geometry2.translate(x_separation / 2, 0, 0)
  // geometry.translate(-x_separation / 2, 0, 0)

  // geometry.rotateX(16 *  3.14159 / 180)
  // geometry2.rotateX(-16 *  3.14159 / 180)

  group1.add(new LineSegments(geometry, lineMaterial));
  group1.add(new Mesh(geometry, meshMaterial));
  group1.rotateX((16 * 3.14159) / 180);

  group2.add(new LineSegments(geometry2, lineMaterial));
  group2.add(new Mesh(geometry2, meshMaterial));
  group2.rotateX((-16 * 3.14159) / 180);

  scene.add(group1);
  group1.translateX(x_separation / 2);
  scene.add(group2);
  group2.translateX(-x_separation / 2);

  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setSize(anchor.clientWidth, anchor.clientHeight);
  renderer.setAnimationLoop(animation);
  anchor.appendChild(renderer.domElement);

  const orbit = new OrbitControls(camera, renderer.domElement);
  orbit.enableZoom = false;
}

function animation(time) {
  // group.rotation.x = time / 2000;
  // group1.rotation.y = -time / 1000;
  // group1.rotation.z = time / 2000;

  const speed = 0.5;
  group1.rotateZ((speed * 3.14159) / 180);
  group1.rotateOnWorldAxis(zAxis, (-speed * 3.14159) / 180);
  group2.rotateZ((speed * 3.14159) / 180);
  group2.rotateOnWorldAxis(zAxis, (-speed * 3.14159) / 180);
  renderer.render(scene, camera);
}

function CoolThreeD() {
  useEffect(() => {
    init();
  }, []);
  return (
    <div
      id="cool-three-d-anchor"
      style={{ width: "100%", height: 500, backgroundColor: "black" }}
    >
      Hello 3D
    </div>
  );
}

export default CoolThreeD;
