import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { Button, TextField } from "@mui/material";
import { useState } from "react";
import App from "../App";
import "../App.css";

export default function LoginWrapper() {
  const [u, setU] = useState({});
  const auth = getAuth();

  const triggerLogin = () => {
    const username = "testaccount@test.com"
    const password = document.getElementById("password").value;
    signInWithEmailAndPassword(auth, username, password);
  };

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      setU(user);
    }
  });

  if (Object.keys(u).length === 0) {
    return <LoginScreen loginCallback={triggerLogin} />;
  } else {
    return <App user={u} />;
  }
}

function LoginScreen({ loginCallback }) {
  return (
    <div className="login">
      <TextField
        id="password"
        label="Password"
        type="password"
        variant="standard"
        style={{ width: 200 }}
      />
      <Button variant="contained" onClick={loginCallback}>
        Log In
      </Button>
    </div>
  );
}
