import { useEffect, useState, useRef } from "react";
import earth from "./earth.jpg";
import Typography from "./Typography";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import theme from "./theme";
import CoolThreeD from "./coolThreeD";
import bg from "./bg.jpg";

import { db } from "./components/firebase";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { doc, getDoc } from "firebase/firestore";

const mattDescription = [
  "Matt is a new player in the crypto-startup scene, looking to work hard and get ahead by being scrappy.",
  "He has been Bitcoin mining* for many years and is tied for the youngest person ever to go to space**.",
  "He is an Ex-NASA, Ex-MIT, Ex-TRA rockstar ninja jedi.",
];

const mattFinePrint = ["*with a shovel", "**camp"];

const juliaDescription = [
  "Julia is a long time listener, first time founder.",
  "She was named one of Fobres Magazine's* 30 under 30, as well as one of the top ten THOT leaders on LinkedIn.",
  "She went to MIT** and brings several minutes of experience in the cryptocurrency space.",
];

const juliaFinePrint = ["*not an actual magazine", "**frat parties"];

const gateauDescription = [
  "I am a seasoned software architect with experience in building fault tolerant distributed systems to replace major financial markets using the newest in web3.0, decentralized technologies.",
];

const neccoDescription = ["KeyError: 'Necco' not found"];

export function useOnScreen(ref) {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsOnScreen(entry.isIntersecting);
      }
    });
  }, []);

  useEffect(() => {
    observerRef.current.observe(ref.current);

    return () => {
      observerRef.current.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}

function TransitionY({ children, start, stop }) {
  const elementRef = useRef(null);
  const isOnScreen = useOnScreen(elementRef);
  return (
    <div
      ref={elementRef}
      style={{
        transition: `all 2s`,
        opacity: isOnScreen ? 1 : 0,
        transform: `translateX(${isOnScreen ? stop : start}%)`,
      }}
    >
      {children}
    </div>
  );
}

function FadeIn({ children }) {
  const elementRef = useRef(null);
  const isOnScreen = useOnScreen(elementRef);
  return (
    <div
      ref={elementRef}
      style={{
        transition: `all 2s`,
        opacity: isOnScreen ? 1 : 0,
        transform: `translatey(${isOnScreen ? 0 : 25}px)`,
      }}
    >
      {children}
    </div>
  );
}

function Emoji({ symbol, label }) {
  return (
    <span
      className="emoji"
      role="img"
      aria-label={label ? label : ""}
      aria-hidden={label ? "false" : "true"}
      style={{ fontSize: 50 }}
    >
      {symbol}
    </span>
  );
}

function UseCaseCard({ header, description, symbols }) {
  return (
    <Card style={{ width: 300, minHeight: 300, margin: 20 }}>
      <CardContent>
        <div className="flex-row">
          {symbols.map((symbol, idx) => (
            <Emoji key={idx} symbol={symbol} />
          ))}
        </div>
        <div className="flex-row">
          <Typography variant="h5">
            {header}
          </Typography>
        </div>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
}

function FounderCard({ name, descriptionArray, finePrintArray, img, imgTag, opacity }) {
  let nameArray = [];
  if (typeof name === "object") {
    nameArray = name;
  } else {
    nameArray = [name];
  }
  return (
    <Card
      style={{
        boxShadow: "none",
        margin: "5vw",
      }}
    >
      <CardContent>
        <CardMedia component="img" alt={name} id={imgTag} style={{'opacity': opacity}} />
        <div style={{ paddingBottom: "10px" }}></div>
        {nameArray.map((name, idx) => (
          <Typography
            key={`n-${idx}`}
            color="inherit"
            align="center"
            variant="h2"
          >
            {name}
          </Typography>
        ))}
        {descriptionArray.map((desc, idx) => (
          <Typography
            key={`d-${idx}`}
            align="left"
            sx={{ fontSize: 20 }}
            color="text.secondary"
            gutterBottom
          >
            {desc}
          </Typography>
        ))}
        {finePrintArray.map((fp, idx) => (
          <Typography
            key={`f-${idx}`}
            sx={{ fontSize: 14 }}
            color="text.secondary"
            gutterBottom
          >
            {fp}
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
}

function CardGrid() {
  return (
    <div className="flex-grid">
      <Grid
        style={{ width: "60vw" }}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container justifyContent="center" xs={12} sm={6}>
          <FadeIn>
            <UseCaseCard
              symbols={["💍", "🙌"]}
              header="Proof of love"
              description="MatriMoney is the most exciting new way to create and issue zero-eccentricity elliptic curves forged out of pure gold, exchanged directly in a peer-to-peer manner, to secure proof-of-love in a publicly-verifiable token."
            />
          </FadeIn>
        </Grid>
        <Grid container justifyContent="center" xs={12} sm={6}>
          <FadeIn>
            <UseCaseCard
              symbols={["👰🏻", "🤵🏻"]}
              header="To have and to hodl"
              description="The concept behind MatriMoney is as simple as it is transformative. By leveraging the power of the blockchain to grant non-fungible tokens of indelible love, our platform is able to guarantee that all parties stay committed to each other regardless of market conditions."
            />
          </FadeIn>
        </Grid>
        <Grid container justifyContent="center" xs={12} sm={6}>
          <FadeIn>
            <UseCaseCard
              symbols={["🤝", "💸"]}
              header="Smart contracts"
              description="Performance Restricting Enforcable NFT Upsell Payments, or PRENUPs, are a type of Smart Contract that enables a distributed consensus-based voting scheme whereby all parties maintain 50% of the voting power regardless of better or worse connections."
            />
          </FadeIn>
        </Grid>
        <Grid container justifyContent="center" xs={12} sm={6}>
          <FadeIn>
            <UseCaseCard
              symbols={["🚀", "🌙"]}
              header="Un-altarable ledgers"
              description="The limited number of MatriMoney tokens and its exclusive offering window ensures a purely deflationary valuation situation, and information stagnation notifications allow for actionable communication personalization."
            />
          </FadeIn>
        </Grid>
      </Grid>
    </div>
  );
}

const calculateTimeLeft = () => {
  const difference = new Date("06/17/2022 5:00 PM EDT") - new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }
  return timeLeft;
};

function App({ user }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [eventDetails, setEventDetails] = useState({});
  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  // useEffect(async () => {
  //   const docRef = doc(db, "private", "details");
  //   const docSnap = await getDoc(docRef);

  //   if (docSnap.exists()) {
  //     setEventDetails(docSnap.data());
  //   }
  // }, [user]);

  useEffect(() => {
    const storage = getStorage();
    getDownloadURL(ref(storage, "invite.jpg")).then((url) => {
      const img = document.getElementById("invite-img");
      img.setAttribute("src", url);
    });

    getDownloadURL(ref(storage, "2022_Letter_to_the_Shareholders.pdf")).then((url) => {
      const frame = document.getElementById("letter");
      frame.setAttribute("src", url);
    });

    getDownloadURL(ref(storage, "FOR IMMEDIATE RELEASE.pdf")).then((url) => {
      const frame = document.getElementById("pressrelease");
      frame.setAttribute("src", url);
    });

    getDownloadURL(ref(storage, "gateau.jpg")).then((url) => {
      const img = document.getElementById("gateau-img");
      img.setAttribute("src", url);
    });

    getDownloadURL(ref(storage, "necco.jpg")).then((url) => {
      const img = document.getElementById("necco-img");
      img.setAttribute("src", url);
    });

    getDownloadURL(ref(storage, "julia.jpg")).then((url) => {
      const img = document.getElementById("bride-img");
      img.setAttribute("src", url);
    });

    getDownloadURL(ref(storage, "matt_portrait.jpg")).then((url) => {
      const img = document.getElementById("groom-img");
      img.setAttribute("src", url);
    });
  }, [user]);

  return (
    <div style={{ overflow: "hidden" }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* <Banner /> */}
        <div
          className="bg"
          style={{
            height: "100vh",
            backgroundImage: `url(${earth})`,
            backgroundSize: "cover",
          }}
        >
          <Typography color="inherit" align="center" variant="h2" marked="none">
            how do you know
          </Typography>
          <Typography color="inherit" align="center" variant="h2" marked="none">
            your love is real...
          </Typography>
        </div>
        <div
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
          }}
        >
          <div style={{ paddingBottom: "50vh" }}></div>
          <TransitionY start={-50} stop={0}>
            <Typography color="inherit" align="center" variant="h2">
              without
            </Typography>
          </TransitionY>
          <div style={{ paddingBottom: "50vh" }}></div>
          <TransitionY start={-50} stop={0}>
            <Typography color="inherit" align="center" variant="h2">
              the
            </Typography>
          </TransitionY>
          <div style={{ paddingBottom: "50vh" }}></div>
          <TransitionY start={-50} stop={0}>
            <Typography color="inherit" align="center" variant="h2">
              blockchain?
            </Typography>
          </TransitionY>
          <div style={{ paddingBottom: "50vh" }}></div>
        </div>
        <CoolThreeD></CoolThreeD>
        <div style={{ paddingBottom: "10vh" }}></div>
        <FadeIn>
          <Typography
            color="inherit"
            align="center"
            variant="h2"
            marked="center"
          >
            Introducing matrimoney
          </Typography>
        </FadeIn>
        <div style={{ paddingBottom: "10vh" }}></div>
        <CardGrid />
        <div style={{ paddingBottom: "10vh" }}></div>
        <FadeIn>
          <Typography
            color="inherit"
            align="center"
            variant="h2"
            marked="center"
          >
            The partners
          </Typography>
        </FadeIn>
        <div style={{ paddingBottom: "10vh" }}></div>
        <div className="flex-grid">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid container justifyContent="center" xs={12} sm={6}>
              <TransitionY start={-50} stop={0}>
                <FounderCard
                  name="Matt"
                  descriptionArray={mattDescription}
                  finePrintArray={mattFinePrint}
                  imgTag="groom-img"
                />
              </TransitionY>
            </Grid>
            <Grid container justifyContent="center" xs={12} sm={6}>
              <TransitionY start={50} stop={0}>
                <FounderCard
                  name="Julia"
                  descriptionArray={juliaDescription}
                  finePrintArray={juliaFinePrint}
                  imgTag="bride-img"
                />
              </TransitionY>
            </Grid>
          </Grid>
        </div>
        <FadeIn>
          <Typography
            color="inherit"
            align="center"
            variant="h2"
            marked="center"
          >
            Our Team
          </Typography>
        </FadeIn>
        <div style={{ paddingBottom: "10vh" }}></div>
        <div className="flex-grid">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid container justifyContent="center" xs={12} sm={6}>
              <TransitionY start={-50} stop={0}>
                <FounderCard
                  name={["Gateau", "Marjolaine"]}
                  descriptionArray={gateauDescription}
                  finePrintArray={[]}
                  imgTag="gateau-img"
                />
              </TransitionY>
            </Grid>
            <Grid container justifyContent="center" xs={12} sm={6}>
              <TransitionY start={50} stop={0}>
                <FounderCard
                  name={["Necco", "Atsume"]}
                  descriptionArray={neccoDescription}
                  finePrintArray={[]}
                  imgTag="necco-img"
                  opacity="0.3"
                />
              </TransitionY>
            </Grid>
          </Grid>
        </div>

        <div style={{ paddingBottom: "10vh" }}></div>
        
        <Typography
          color="inherit"
          align="center"
          variant="h2"
          marked="center"
          id="2theshareholders"
        >
          2022 Letter To The Shareholders
        </Typography>
        
        <div style={{ paddingBottom: "10vh" }}></div>
        
          <div className="flex-row">
            <iframe id="letter" style={{'width': '90vw', 'height': '100vh'}}></iframe>
          </div>
        
        <div style={{ paddingBottom: "10vh" }}></div>

        <div style={{ paddingBottom: "10vh" }}></div>
        
        <Typography
          color="inherit"
          align="center"
          variant="h2"
          marked="center"
          id="pressrelease-2024"
        >
          2024 Press Release
        </Typography>
        
        <div style={{ paddingBottom: "10vh" }}></div>
        
          <div className="flex-row">
            <iframe id="pressrelease" style={{'width': '90vw', 'height': '100vh'}}></iframe>
          </div>
        
        <div style={{ paddingBottom: "10vh" }}></div>
      </ThemeProvider>
    </div>
  );
}

export default App;
