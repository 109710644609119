import React from "react";
import ReactDOM from "react-dom";

import LoginWrapper from "./components/loginWrapper";

ReactDOM.render(
  <React.StrictMode>
    <LoginWrapper />
  </React.StrictMode>,
  document.getElementById("root")
);
