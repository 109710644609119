// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAFqY-SGLcFQol1303VmPOqhfPbh8fdmpI",
  authDomain: "wedding-24972.firebaseapp.com",
  projectId: "wedding-24972",
  storageBucket: "wedding-24972.appspot.com",
  messagingSenderId: "988006392762",
  appId: "1:988006392762:web:d13adbde6b9b6c07147383",
  measurementId: "G-QMCETD2GTC"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore();

